import React, { useEffect, useState } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { ConfirmProvider } from 'material-ui-confirm';
import { Box, ThemeProvider, BoxProps } from '@mui/material';
import { useDispatch } from 'react-redux';
import { FlagProvider, IConfig } from '@unleash/proxy-client-react';
import TagManager from 'react-gtm-module';
// import getCountry from './helper/Strings';
// HOOKS
import { useAppSelector } from './hooks';
// STORE
import { getThemeMode } from './store/ConfigSlice';
import { getAuthState } from './store/AuthSlice';
// THEME
import { LightTheme, DarkTheme } from './theme';
// PAGES
import InvalidRoute from './pages/Validate/InvalidRoute';
import Onboarding from './pages/Onboarding';
import Trial from './pages/Trial';
// COMPONENTS
import ErrorAlert from './components/Alert/ErrorAlert';
import FullScreenLoader from './components/Loaders/FullScreenloader';
import getClientCredentialsToken from './store/AuthSlice/AuthApi';

const containerStyles: BoxProps['sx'] = {
  backgroundColor: 'background.default',
  color: 'text.primary',
  height: '100vh',
};

const featureFlagConfig: IConfig = {
  url: `${process.env.REACT_APP_FEATURE_FLAG_URL}/proxy`, // Your front-end API URL or the Unleash proxy's URL (https://<proxy-url>/proxy)
  clientKey: process.env.REACT_APP_FEATURE_FLAG_CLIENT_KEY as string, // A client-side API token OR one of your proxy's designated client keys (previously known as proxy secrets)
  refreshInterval: 0, // How often (in seconds) the client should poll the proxy for updates
  appName: process.env.REACT_APP_FEATURE_FLAG_APP_NAME as string, // The name of your application. It's only used for identifying your application
};

const App: React.FC = () => {
  const [gettingToken, setGettingToken] = useState(true);

  // HOOKS
  const dispatch = useDispatch();
  const themeMode = useAppSelector(getThemeMode);
  const authState = useAppSelector(getAuthState);

  const theme = themeMode === 'dark' ? DarkTheme : LightTheme;
  // const country = getCountry();

  useEffect(() => {
    dispatch(getClientCredentialsToken());

    // Custom redirect
    // if (country === 'Asia/Kuala_Lumpur') {
    //   window.location.href = `${process.env.REACT_APP_LEARNING_PLATFORM_FE_URL}/portal/tutors`;
    // }
  }, []);

  useEffect(() => {
    TagManager.initialize({ gtmId: 'GTM-TN697WW' });
  }, []);

  useEffect(() => {
    if (authState.status !== 'loading') {
      setGettingToken(false);
    }
  }, [authState.status]);

  if (
    (gettingToken && authState.status === 'idle') ||
    authState.status === 'loading'
  ) {
    return <FullScreenLoader />;
  }

  // RENDER
  return (
    <FlagProvider config={featureFlagConfig}>
      <ThemeProvider theme={theme}>
        <ConfirmProvider>
          <Box
            data-testid="main-app-container"
            className="App"
            sx={containerStyles}
          >
            <BrowserRouter>
              <Routes>
                <Route path="/" element={<Navigate to="/onboarding" />} />
                <Route path="*" element={<InvalidRoute />} />
                <Route path="/onboarding/*" element={<Onboarding />} />
                <Route path="/trial/*" element={<Trial />} />
              </Routes>
            </BrowserRouter>
            <ErrorAlert />
          </Box>
        </ConfirmProvider>
      </ThemeProvider>
    </FlagProvider>
  );
};

export default App;
